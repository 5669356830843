import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Xcelerator.png";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import "./Home.scss";
import { getLandingMenuList } from "../../Redux/API/api_admin";
import { storeLandingMenuItemsAction } from "../../Redux/Actions/actions";

export default function Home() {
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector(
    (state) => state.saveBearerTokenReducer.bearerToken
  );
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  const landingMenuItems =
    useSelector((state) => state.storeLandingMenuItemsReducer.menuItems) || [];

  function getMenus() {
    getLandingMenuList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId
    )
      .then((response) => {
        if (response) {
          console.log(response);
          dispatch(storeLandingMenuItemsAction(response));
        } else {
          dispatch(storeLandingMenuItemsAction([]));
        }
      })
      .catch((error) => {
        console.log("Error: " + error, "error");
      });
  }

  useEffect(() => {
    if (
      userDetails?.userId > 0 &&
      siteToken !== "" &&
      landingMenuItems?.length <= 0
    ) {
      getMenus();
    }
  }, [userDetails]);

  return (
    <div className="home-component">
      <div className="litigation-master-block">
        <main className="">
          {/* <img
            src={Cognisphere}
            alt="Cognisphere"
            className="homepage-xcelerator"
          /> */}
          {landingMenuItems.length ? (
            <div className="bg-landing-page">
              <Grid container className="homepage-container">
                <Grid item xs={12} sm={12} md={12} lg={10} className="z-9">
                  <iframe
                    title="Litigation_Dashboard_Test"
                    width="1080"
                    height="550"
                    src="https://app.powerbi.com/reportEmbed?reportId=beb88594-3662-439c-baab-7b6b4a4239b9&autoAuth=true&ctid=38ae3bcd-9579-4fd4-adda-b42e1495d55a"
                    frameborder="0"
                    allowFullScreen="true"></iframe>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  className="home-page-menu">
                  <Grid container spacing={2}>
                    {landingMenuItems.map((item, index) => (
                      <Grid item xs={12} sm={12} md={12} key={index}>
                        <Link to={item.menuLink} key={index}>
                          <div className="menu-item-container">
                            <div className="menu-item-text-block">
                              <span className="align-center ml-1">
                                {item.menuName}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <Loader />
          )}
        </main>
      </div>
    </div>
  );
}
