import {
  AddBox,
  Check,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Clear,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import HomeIcon from "@material-ui/icons/Home";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
  )),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const tableIconsInputScreen = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => (
    <Check {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
  )),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export let customStylesDefault = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    borderBottom: `1px solid #00B3B3`,
    borderRadius: 0,
    cursor: "text",
    boxShadow: "0 0 0 0 transparent !important",
    minHeight: 0,
    ":hover": {
      border: "none",
      borderBottom: `1px solid #00B3B3`,
      borderRadius: 0,
      boxShadow: "0 0 0 0 transparent !important",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  input: (provided, state) => ({
    ...provided,
    textAlign: "left",
    padding: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: "left",
    backgroundColor: `var(--select-active-bg-color)`,
    color: `var(--select-active-text-color)`,
  }),
  menuList: (provided, state) => ({
    ...provided,
    color: `var(--select-active-text-color)`,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "1px !important",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "1px !important",
    cursor: "pointer",
  }),
};
export let customStylesYear = {
  control: (provided, state) => ({
    ...provided,
    cursor: "text",
    boxShadow: "0 0 0 0 transparent !important",
    minHeight: 0,
    ":hover": {
      boxShadow: "0 0 0 0 transparent !important",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  input: (provided, state) => ({
    ...provided,
    textAlign: "left",
    padding: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: "left",
    backgroundColor: `var(--select-active-bg-color)`,
    color: `var(--select-active-text-color)`,
  }),
  menuList: (provided, state) => ({
    ...provided,
    color: `var(--select-active-text-color)`,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "1px !important",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "1px !important",
    cursor: "pointer",
  }),
};
export const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: `var(--side-drawer-bg-color)`,
    primary25: `var(--app-permission-list-item-color)`,
    primary50: `var(--app-permission-list-item-color)`,
  },
});
export const homeScreenIconStyle = {
  padding: "5px",
  margin: "5px -20px 0px 10px",
};

//! Home Page Menus -- IDT
export const LandingSuperAdmin = [
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/admin",
    menuName: "Administration",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },

  {
    roleId: 1,
    role: "Admin",
    menuLink: "/ViewToolCase",
    menuName: "View Tool Cases",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/reports",
    menuName: "Reports",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/payment",
    menuName: "Payment",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/refundList",
    menuName: "Refund",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];
export const LandingIDTTaxProfessional = [
  {
    roleId: 2,
    role: "IDT Tax Professional",
    menuLink: "/ToolCaseRegistration",
    menuName: "Create Tool Case",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 2,
    role: "IDT Tax Professional",
    menuLink: "/ViewToolCase",
    menuName: "View Tool Cases",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 2,
    role: "IDT Tax Professional",
    menuLink: "/reports",
    menuName: "Reports",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 2,
    role: "IDT Tax Professional",
    menuLink: "/payment",
    menuName: "Payment",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 2,
    role: "IDT Tax Professional",
    menuLink: "/refundList",
    menuName: "Refund",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];
export const LandingIDTTaxManager = [
  {
    roleId: 3,
    role: "IDT Tax Manager",
    menuLink: "/ViewToolCase",
    menuName: "View Tool Cases",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 3,
    role: "IDT Tax Manager",
    menuLink: "/reports",
    menuName: "Reports",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 3,
    role: "IDT Tax Manager",
    menuLink: "/payment",
    menuName: "Payment",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 3,
    role: "IDT Tax Manager",
    menuLink: "/refundList",
    menuName: "Refund",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];
export const LandingIDTHead = [
  {
    roleId: 4,
    role: "IDT Tax Head",
    menuLink: "/ViewToolCase",
    menuName: "View Tool Cases",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 4,
    role: "IDT Tax Head",
    menuLink: "/reports",
    menuName: "Reports",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 4,
    role: "IDT Tax Head",
    menuLink: "/payment",
    menuName: "Payment",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 4,
    role: "IDT Tax Head",
    menuLink: "/refundList",
    menuName: "Refund",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];
export const LandingIDTAdmin = [
  {
    roleId: 5,
    role: "IDT Tax Admin",
    menuLink: "/ViewToolCase",
    menuName: "View Tool Cases",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 5,
    role: "IDT Tax Admin",
    menuLink: "/reports",
    menuName: "Reports",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 5,
    role: "IDT Tax Admin",
    menuLink: "/payment",
    menuName: "Payment",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 5,
    role: "IDT Tax Admin",
    menuLink: "/refundList",
    menuName: "Refund",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];

export const LandingMenus = [
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/",
    menuName: "Indirect Tax (IDT)",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/Customs",
    menuName: "Customs",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 2,
    role: "IDT Tax Professional",
    menuLink: "/",
    menuName: "Indirect Tax (IDT)",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 3,
    role: "IDT Tax Manager",
    menuLink: "/",
    menuName: "Indirect Tax (IDT)",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 4,
    role: "IDT Head",
    menuLink: "/",
    menuName: "Indirect Tax (IDT)",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 5,
    role: "IDT Admin",
    menuLink: "/",
    menuName: "Indirect Tax (IDT)",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
];
export const AdminMenus = [
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/userManagement",
    menuName: "User Management",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/hardcodeMaster",
    menuName: "HardCode Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/linkedMasterList",
    menuName: "Linked Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/companyList",
    menuName: "Company Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/division",
    menuName: "Division Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/buList",
    menuName: "Business Unit Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/bsList",
    menuName: "Business Segment Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/costCenter",
    menuName: "Cost Center",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/poList",
    menuName: "PO Master",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
];
export const MasterRouteLanding = [
  ...LandingSuperAdmin,
  ...LandingIDTTaxProfessional,
  ...LandingIDTHead,
  ...LandingIDTTaxManager,
  ...LandingIDTAdmin,
];
export const LandingMenu = [...LandingMenus];
export const AdminMenu = [...AdminMenus];


//Prod
export const baseApiURL = "https://app-sli-litmusapi-prod-ci-1.azurewebsites.net/api";
export const baseAddressURL = "https://litmus.cft-in.siemens.cloud";

//constants
export const IDTModuleId = 1;
export const CustomModuleId = 2;
export const CreateEnqModuleId = 1;
export const CreateScnModuleId = 3;
export const VDModuleId = 2;
export const VATCSTModuleId = 4;
export const IDTRefundID = 6;

export const getURLParameter = (sParam) => {
  var sPageURL = Buffer.from(
    window.location.search.substring(1),
    "base64"
  ).toString();
  var sURLVariables = sPageURL.split("&");
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split("=");
    var value = "";
    for (var j = 0; j < sParameterName.length; j++) {
      if (sParameterName[j] === sParam) {
        for (var k = j + 1; k < sParameterName.length; k++) {
          value = value + sParameterName[k];
        }
        break;
      }
      //return sParameterName[1];
    }
    if (!value) {
      continue;
    } else {
      break;
    }
  }
  return decodeURI(value);
};
export const spocEmail = "mayukh.mukherjee.ext@siemens.com";

export const AdminSideDrawerItems = [
  {
    icon: <HomeIcon />,
    title: "Home",
    link: "/",
    roleId: 1,
    role: "Super Admin",
    },
    {
        icon: <HomeIcon />,
        title: "Administration",
        link: "/admin",
        roleId: 1,
        role: "Super Admin",
    },
  {
    icon: <ListAltOutlinedIcon />,
    title: "View Tool Case",
    link: "/ViewToolCase",
    roleId: 1,
    role: "Super Admin",
    },
    {
        icon: <ListAltOutlinedIcon />,
        title: "Reports",
        link: "/reports",
        roleId: 1,
        role: "Super Admin",
    },
    {
        icon: <ListAltOutlinedIcon />,
        title: "Payment",
        link: "/payment",
        roleId: 1,
        role: "Super Admin",
    },
    {
        icon: <ListAltOutlinedIcon />,
        title: "Refund",
        link: "/refundList",
        roleId: 1,
        role: "Super Admin",
    },

  
 
  {
    icon: <PowerSettingsNewOutlinedIcon />,
    title: "Logout",
    link: "logout",
    roleId: 1,
    role: "Super Admin",
  },
];
export const IDTTaxProfSideDrawerItems = [
  {
    icon: <HomeIcon />,
    title: "Home",
    link: "/",
    roleId: 2,
    role: "Tax Professional",
  },
  {
    icon: <ListAltOutlinedIcon />,
    title: "Create Tool Case",
    link: "/ToolCaseRegistration",
    roleId: 2,
    role: "Tax Professional",
    },
    
    
  {
    icon: <ListAltOutlinedIcon />,
    title: "View Tool Case",
    link: "/ViewToolCase",
    roleId: 2,
    role: "Tax Professional",
  },

  {
    icon: <AssessmentIcon />,
    title: "Reports",
    link: "/reports",
    roleId: 2,
    role: "Tax Professional",
    },
    {
        icon: <ListAltOutlinedIcon />,
        title: "Payment",
        link: "/payment",
        roleId: 2,
        role: "Tax Professional",
    },
    {
        icon: <ListAltOutlinedIcon />,
        title: "Refund",
        link: "/refundList",
        roleId: 2,
        role: "Tax Professional",
    },
  {
    icon: <PowerSettingsNewOutlinedIcon />,
    title: "Logout",
    link: "logout",
    roleId: 2,
    role: "Tax Professional",
  },
];
export const IDTTaxManagerSideDrawerItems = [
  {
    icon: <HomeIcon />,
    title: "Home",
    link: "/",
    roleId: 3,
    role: "Tax Manager",
  },
  {
    icon: <ListAltOutlinedIcon />,
    title: "View Tool Case",
    link: "/ViewToolCase",
    roleId: 3,
    role: "Tax Manager",
  },
  {
    icon: <AssessmentIcon />,
    title: "Reports",
    link: "/reports",
    roleId: 3,
    role: "Tax Manager",
    },
    {
        icon: <HomeIcon />,
        title: "Payment",
        link: "/payment",
        roleId: 3,
        role: "Tax Manager",
    },
    {
        icon: <HomeIcon />,
        title: "Refund",
        link: "/refundList",
        roleId: 3,
        role: "Tax Manager",
    },
  {
    icon: <PowerSettingsNewOutlinedIcon />,
    title: "Logout",
    link: "logout",
    roleId: 3,
    role: "Tax Manager",
  },
];
export const IDTTaxHeadSideDrawerItems = [
  {
    icon: <HomeIcon />,
    title: "Home",
    link: "/",
    roleId: 4,
    role: "Tax Head",
  },
  {
    icon: <ListAltOutlinedIcon />,
    title: "View Tool Case",
    link: "/ViewToolCase",
    roleId: 4,
    role: "Tax Head",
  },
  {
    icon: <AssessmentIcon />,
    title: "Reports",
    link: "/reports",
    roleId: 4,
    role: "Tax Head",
    },
    {
        icon: <AssessmentIcon />,
        title: "Payment",
        link: "/payment",
        roleId: 4,
        role: "Tax Head",
    },
    {
        icon: <AssessmentIcon />,
        title: "Refund",
        link: "/refundList",
        roleId: 4,
        role: "Tax Head",
    },
  {
    icon: <PowerSettingsNewOutlinedIcon />,
    title: "Logout",
    link: "logout",
    roleId: 4,
    role: "Tax Head",
  },
];
export const IDTTaxAdminSideDrawerItems = [
  {
    icon: <HomeIcon />,
    title: "Home",
    link: "/",
    roleId: 5,
    role: "Tax Admin",
  },
  {
    icon: <ListAltOutlinedIcon />,
    title: "View Tool Case",
    link: "/ViewToolCase",
    roleId: 5,
    role: "Tax Admin",
  },
  {
    icon: <AssessmentIcon />,
    title: "Reports",
    link: "/reports",
    roleId: 5,
    role: "Tax Admin",
    },
    {
        icon: <AssessmentIcon />,
        title: "Payment",
        link: "/payment",
        roleId: 5,
        role: "Tax Admin",
    },
    {
        icon: <AssessmentIcon />,
        title: "Refund",
        link: "/refundList",
        roleId: 5,
        role: "Tax Admin",
    },
  {
    icon: <PowerSettingsNewOutlinedIcon />,
    title: "Logout",
    link: "logout",
    roleId: 5,
    role: "Tax Admin",
  },
];
export const MasterSideDrawer = [
  ...AdminSideDrawerItems,
  ...IDTTaxProfSideDrawerItems,
  ...IDTTaxManagerSideDrawerItems,
  ...IDTTaxHeadSideDrawerItems,
  ...IDTTaxAdminSideDrawerItems,
];


export function decodeData(value) {
  const { atob } = require("abab");
  let arrValue = atob(atob(value)).split(" ");
  let valueActual = arrValue[0];
  return valueActual;
}